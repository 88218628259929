<template>
  <div>
    <div class="flex" v-show="!hierarchySearch">
      <input
        type="text"
        :placeholder="rndPlaceholder"
        :value="bouncedSearchQuery"
        @input="(e) => (bouncedSearchQuery = e.target.value)"
        @click="inputClicked"
      />
    </div>

    <div class="types">
      <div class="flex" v-show="!hierarchySearch && !showSuggest">
        <span class="menu-badge">
          <span class="menu-badge_text">Neu</span>
          <a href="https://chat.heyjura.de" target="_blank" >⚡️KI-Tutor</a>
        </span>
        <!--<span @click="$router.push('/casesFullSearch')">Fälle 🚀</span>-->
        <span @click="$router.push('/fullSearch')">Schematische Suche</span>
      </div>

      <div
        class="flex"
        v-show="(searchQuery != '' || hierarchySearch) && !hideFilters && !showSuggest"
      >
        <span :class="{ active: type == 'schemata' }" @click="switchType('schemata')"
          >Schemata</span
        >
        <span :class="{ active: type == 'topics' }" @click="switchType('topics')"
          >Definitionen</span
        >
      </div>
    </div>
    <div class="wrapper-suggestion">
      <div v-if="showSuggest && searchQuery !== ''">
        <div class="suggestion-text">
          <span v-if="isLoggedIn">Kein Ergebnis? Sollen wir etwas hochladen?</span>
          <span v-else
            >Kein Ergebnis? Sollen wir etwas hochladen?</span
          >
        </div>
        <button class="button-suggestion tick" @click="sendSuggestion">Ja, bitte!</button>
        <div class="tick"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  props: ['hierarchySearch', 'hideFilters', 'showSuggest'],
  data() {
    return {
      searchQuery: '',
      timeout: null,
      type: '',
    };
  },
  methods: {
    search() {
      this.$emit('searchQueryChange', {
        searchQuery: this.searchQuery,
        type: this.type,
      });
    },
    switchType(type) {
      if (type == 'schemata') this.type = 'schemata';
      if (type == 'topics') this.type = 'topics';
    },
    inputClicked() {
      //Check Mobile
      if (window.screen.width > 700) return;

      //Dirty Fix
      setTimeout(() => {
        const elem = this.$el.querySelector('input');
        const y = elem.getBoundingClientRect().top + window.pageYOffset - 25;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 200);
    },
    async sendSuggestion() {
      try {
        console.log(this.$store.state.user.jwt);
        const response = await this.$api.post(
          '/suggestions',
          {
            data: {
              term: this.bouncedSearchQuery,
            },
          },
          {
            customData: {
              userToken:
                this.$store.state.user && this.$store.state.user.jwt
                  ? this.$store.state.user.jwt
                  : '', // Check if the token exists before adding it to the request
            },
          }
        );

        console.log(response.data);

        let text = document.querySelector('.suggestion-text');
        let button = document.querySelector('.button-suggestion');
        let buttonText = document.querySelector('.tick');
        const tickMark =
          '<svg width="20" height="20" viewBox="0 0 58 45" xmlns="http://www.w3.org/2000/svg"><path fill="#fff" fill-rule="nonzero" d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65"/></svg>';
        if (buttonText.innerHTML !== 'Ja, bitte!') {
          buttonText.innerHTML = 'Ja, bitte!';
        } else if (buttonText.innerHTML === 'Ja, bitte!') {
          buttonText.innerHTML = tickMark;
        }
        button.classList.toggle('button__circle__search');
        text.innerHTML = 'Danke dir. Wir kümmern uns drum.';
        setTimeout(() => {
          this.searchQuery = '';
        }, 2000);
      } catch (error) {
        console.error(error);
      }
    },
  },
  watch: {
    searchQuery() {
      this.search();
    },
    type() {
      this.search();
    },
  },
  computed: {
    isLoggedIn() {
      return !!this.$store.state.user;
    },
    bouncedSearchQuery: {
      get() {
        return this.searchQuery;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchQuery = val;
        }, 300);
      },
    },
    rndPlaceholder() {
      const placeholders = [
        'z.B. Wegnahme, Diebstahl oder § 242 StGB',
        'z.B. Meinungsfreiheit oder Art. 5 I GG',
        'z.B. EBV, Herausgabeanspruch oder § 985 BGB',
      ];

      return placeholders[Math.floor(Math.random() * placeholders.length)];
    },
  },
};
</script>

<style>
.button-suggestion {
  text-align: center;
  margin: 20px 20px;
  padding: 10px 35px;
  border-radius: 50px;
  font-size: 1em;
  /* transition: all 0.3s cubic-bezier(0.67, 0.17, 0.4, 0.83); */
  background: #4285f4;
  transition: background-color 0.3s ease;

  @media screen and (max-width: 640px) {
    font-size: 14px;
    padding: 8px 27px;
  }
}
.button-suggestion:hover {
  background: #6fc276;
}
.button-suggestion svg {
  width: 20px;
  align-self: center;
  transform: rotate(180deg);
  transition: all 0.3s;
}

.button__circle__search {
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 1em;
  padding: 0 !important;
  background: mediumseagreen;
  border-radius: 50%;
  transform: rotate(-180deg);
}
</style>

<style lang="scss" scoped>
@import '../assets/css/_variables';

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

span a{
  text-decoration: none;
  color: #1b1b1b;
  &:hover {
    opacity: 1;
    color: #4285f4;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}

span:not(.suggestion-text span),
p {
  opacity: 0.4;
  margin: 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.4s;

  &:hover {
    opacity: 1;
    color: #4285f4;
  }

  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
}

.active {
  opacity: 1;
  color: #4285f4;
}

.menu-badge {
  line-height: normal;
  color: #00D42D;
}

.menu-badge_text {
  background: rgba(0, 212, 45, .2) !important;
  padding: 0.15em 0.7em;
  margin: 0px !important;
  border-radius: 2px;
  opacity: 1 !important;
  font-weight: 600;
  letter-spacing: -0.02em;
  text-transform: initial;
}

.menu-badge_text:hover{
  color: #00D42D !important;
}

.types > .flex {
  margin: 0;
}

input {
  font-size: 1.25em;

  border: none;
  border-radius: 50px;
  max-width: 500px;
  flex-basis: auto;
  flex-grow: 1;
  padding: 25px 40px;

  transition: box-shadow 0.4s;

  outline: none;

  -webkit-appearance: none;
  box-shadow: 0px 10px 70px rgb(0 0 0 / 6%);

  &:focus {
    box-shadow: 0px 10px 70px rgb(0 0 0 / 14%);
  }

  /*
  box-shadow: 0 4px 5px 0 #e8e8e8, 0 6px 20px 0 #e8e8e8;

  &:focus {
    box-shadow: 0 4px 5px 0 #bebebe, 0 6px 20px 0 #bebebe;
    
  }
  */

  @media screen and (max-width: 640px) {
    padding: 18px 19px;
    font-size: 1.2em;

    &::placeholder {
      font-size: 0.8em;
    }
  }
}
.wrapper-suggestion {
  display: flex;
  justify-content: center;
  text-align: center;
}

.button:hover {
  cursor: pointer;
}

.tick {
  color: white;
  transition: all 0.6s;
}

.suggestion-text {
  opacity: 0.4;
}
</style>
